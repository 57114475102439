import { useContext } from "react";
import {
  ILanguageSwitcherCountry,
  ILanguageSwitcherCountryLanguage,
  LanguageSwitcher as FactorLanguageSwitcher,
} from "@lleed-partners/factor";
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";

const languages = [
  "en",
  "fr",
  "fr-ca",
  "en-ca",
  "en-us",
  "en-mx",
  "en-gb",
  "en-ie",
  "en-sg",
  "en-au",
  "en-nz",
  "fr-fr",
  "fr-ch",
  "fr-lu",
  "fr-be",
] as const;

type TLanguages = typeof languages[number];

interface ILanguageSwitcherCountryLanguageApplied
  extends ILanguageSwitcherCountryLanguage {
  code: TLanguages;
}

interface ILanguageSwitcherCountryApplied extends ILanguageSwitcherCountry {
  languages: ILanguageSwitcherCountryLanguageApplied[];
}

const countries: ILanguageSwitcherCountryApplied[] = [
  {
    name: "Canada",
    region: { region: "America", name: "America" },
    flag: "CA",
    languages: [
      {
        name: "English",
        code: "en-ca",
      },
      {
        name: "French",
        code: "fr-ca",
      },
    ],
  },
  {
    name: "United States",
    region: { region: "America", name: "America" },
    flag: "US",
    languages: [
      {
        name: "English",
        code: "en-us",
      },
    ],
  },
  {
    name: "Mexico",
    region: { region: "America", name: "America" },
    flag: "MX",
    languages: [
      {
        name: "English",
        code: "en-mx",
      },
    ],
  },
  {
    name: "United Kingdom",
    region: { region: "Europe", name: "Europe" },
    flag: "GBR",
    languages: [
      {
        name: "English",
        code: "en-gb",
      },
    ],
  },
  {
    name: "Ireland",
    region: { region: "Europe", name: "Europe" },
    flag: "IE",
    languages: [
      {
        name: "English",
        code: "en-ie",
      },
    ],
  },
  {
    name: "France",
    region: { region: "Europe", name: "Europe" },
    flag: "FR",
    languages: [
      {
        name: "French",
        code: "fr-fr",
      },
    ],
  },
  {
    name: "Switzerland",
    region: { region: "Europe", name: "Europe" },
    flag: "CH",
    languages: [
      {
        name: "French",
        code: "fr-ch",
      },
    ],
  },
  {
    name: "Belgium",
    region: { region: "Europe", name: "Europe" },
    flag: "BE",
    languages: [
      {
        name: "French",
        code: "fr-be",
      },
    ],
  },
  {
    name: "Luxembourg",
    region: { region: "Europe", name: "Europe" },
    flag: "LU",
    languages: [
      {
        name: "French",
        code: "fr-lu",
      },
    ],
  },
  {
    name: "Singapore",
    region: { region: "Asia", name: "Asia" },
    flag: "SG",
    languages: [
      {
        name: "English",
        code: "en-sg",
      },
    ],
  },
  {
    name: "Australia",
    region: { region: "Oceania", name: "Oceania" },
    flag: "AU",
    languages: [
      {
        name: "English",
        code: "en-au",
      },
    ],
  },
  {
    name: "New Zealand",
    region: { region: "Oceania", name: "Oceania" },
    flag: "NZ",
    languages: [
      {
        name: "English",
        code: "en-nz",
      },
    ],
  },
  {
    region: { region: "World", name: "World" },
    languages: [
      {
        name: "English",
        code: "en",
      },
      {
        name: "French",
        code: "fr",
      },
    ],
  },
];

export const LanguageSwitcher = () => {
  const { language } = useContext(I18nextContext);
  const { t, changeLanguage } = useI18next();

  return (
    <FactorLanguageSwitcher
      changeLanguage={changeLanguage}
      countries={countries.map((country) => ({
        ...country,
        /* i18next-extract-disable-next-line */
        name: country.name ? t(country.name) : undefined,
        region: {
          ...country.region,
          /* i18next-extract-disable-next-line */
          name: t(country.region.name),
        },
        languages: country.languages.map((language) => ({
          ...language,
          /* i18next-extract-disable-next-line */
          name: t(language.name),
        })),
      }))}
      active={language}
    />
  );
};
