import { ReactNode } from "react";
import { Icon as FactorIcon } from "@lleed-partners/factor";

import * as preloadedIcons from "./preloaded";

export type TPreloadedIconNames = keyof typeof preloadedIcons;

interface IIconProps {
  /** Icon as a react component */
  icon?: ReactNode;
  /** Name of the icon (for dynamic imports) */
  name?: TPreloadedIconNames;
}

const DefaultIcon = () => <svg />;

export const Icon = ({ name, icon }: IIconProps) => {
  const IconElement =
    name && preloadedIcons[name] ? preloadedIcons[name] : null;

  return (
    <FactorIcon
      icon={icon || (IconElement && <IconElement />) || <DefaultIcon />}
    />
  );
};
