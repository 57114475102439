import { useStaticQuery, graphql } from "gatsby";
import {
  Footer as FactorFooter,
  FooterLink,
  FooterLinksCategory,
  useConsent,
} from "@lleed-partners/factor";
import { Icon } from "../Icon";
import { Launch32 } from "@carbon/icons-react";
import { useContext, useMemo } from "react";
import { Link, useI18next, I18nextContext } from "gatsby-plugin-react-i18next";
import { LanguageSwitcher } from "./language-switcher";
import Obfuscate from "react-obfuscate";
import { trail } from "..";

export const Footer = () => {
  const { t } = useI18next();
  const { language } = useContext(I18nextContext);
  const { showConsent } = useConsent();

  const {
    services: { edges: services },
    industries: { edges: industries },
  } = useStaticQuery(graphql`
    query {
      services: allMdx(
        filter: {
          frontmatter: { category: { eq: "service" }, service: { eq: null } }
        }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
            }
            fields {
              langKey
            }
            subServices {
              frontmatter {
                slug
                title
              }
            }
          }
        }
      }

      industries: allMdx(
        filter: { frontmatter: { category: { eq: "industry" } } }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
            }
            fields {
              langKey
            }
            subServices {
              frontmatter {
                slug
                title
              }
            }
          }
        }
      }
    }
  `);

  const filteredServices = useMemo(() => {
    return services
      .filter(({ node }) => node.fields.langKey === language.split("-")[0])
      .map(({ node }) => ({
        ...node,
        subServices: node.subServices.sort((a, b) =>
          a.frontmatter.slug.localeCompare(b.frontmatter.slug)
        ),
      }));
  }, [language, services]);

  const filteredIndustries = useMemo(() => {
    return industries
      .filter(({ node }) => node.fields.langKey === language.split("-")[0])
      .map(({ node }) => ({
        ...node,
      }));
  }, [language, industries]);

  return (
    <FactorFooter languageSwitcher={<LanguageSwitcher />}>
      <FooterLinksCategory title={t("About")}>
        <FooterLink>{t("Lleed & Partners S.à.R.L.")}</FooterLink>
        <FooterLink>
          {t("Rue de la cité 1, 1204 Geneva, Switzerland")}
        </FooterLink>
        <FooterLink>CHE–279.374.453</FooterLink>
        <FooterLink>© 2021 - Lleed & Partners</FooterLink>
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Quick access")}>
        <FooterLink to="/" as={Link}>
          {t("Home")}
        </FooterLink>
        <FooterLink to="/sitemap/" as={Link}>
          {t("Sitemap")}
        </FooterLink>
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Contact")}>
        <FooterLink as={Link} to="/contact/">
          {t("Contact portal")}
        </FooterLink>
        <FooterLink as={Link} to="/contact/#business">
          {t("Business enquiry")}
        </FooterLink>
        <FooterLink as={Link} to="/contact/#support">
          {t("Support request")}
        </FooterLink>
        <FooterLink as={Link} to="/contact/#general">
          {t("General enquiry")}
        </FooterLink>
        <FooterLink
          icon={<Icon name="Email32" />}
          as={Obfuscate}
          headers={{
            subject: t("Getting in touch with Lleed & Partners"),
            body: t(
              "Please, give us more information about your contact here."
            ),
          }}
          email="contact@lleedpartners.com"
          obfuscateChildren={false}
        >
          {t("Email")}
        </FooterLink>
        <FooterLink
          icon={<Icon name="Phone32" />}
          as={Obfuscate}
          tel="+41 78 235 16 00"
          obfuscateChildren={false}
        >
          {t("Phone")}
        </FooterLink>
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Careers")}>
        <FooterLink
          as="a"
          rel="noopener"
          target="_blank"
          href="https://www.linkedin.com/company/lleedpartners/jobs/"
          icon={<Icon icon={<Launch32 />} />}
        >
          LinkedIn Jobs
        </FooterLink>
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Social")}>
        <FooterLink
          as="a"
          rel="noopener"
          target="_blank"
          href="https://www.linkedin.com/company/lleedpartners/"
          icon={<Icon icon={<Launch32 />} />}
        >
          LinkedIn
        </FooterLink>
        <FooterLink
          as="a"
          rel="noopener"
          target="_blank"
          href="https://www.crunchbase.com/organization/lleed-partners"
          icon={<Icon icon={<Launch32 />} />}
        >
          CrunchBase
        </FooterLink>
        <FooterLink
          as="a"
          rel="noopener"
          target="_blank"
          href="https://www.facebook.com/LleedPartners"
          icon={<Icon icon={<Launch32 />} />}
        >
          Facebook
        </FooterLink>
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Services")}>
        {filteredServices.map((service) => {
          return (
            <FooterLink
              as={Link}
              to={trail(service.frontmatter.slug)}
              key={service.frontmatter.slug}
            >
              {service.frontmatter.title}
            </FooterLink>
          );
        })}
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Industries")}>
        {filteredIndustries.map((industry) => {
          return (
            <FooterLink
              as={Link}
              to={trail(industry.frontmatter.slug)}
              key={industry.frontmatter.slug}
            >
              {industry.frontmatter.title}
            </FooterLink>
          );
        })}
      </FooterLinksCategory>
      <FooterLinksCategory title={t("Legal")}>
        <FooterLink as={Link} to="/legal/" key="legal">
          {t("Legal information")}
        </FooterLink>
        <FooterLink as={Link} to="/legal/privacy/" key="legal">
          {t("Privacy policy")}
        </FooterLink>
        <FooterLink as="button" onClick={showConsent} key="legal">
          {t("Cookie consent")}
        </FooterLink>
      </FooterLinksCategory>
    </FactorFooter>
  );
};
