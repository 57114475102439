import { IChildrenElementProps } from "@lleed-partners/factor/src/typings/props";

import { Footer } from "../fragments/Footer";
import { Nav, INavProps } from "../fragments/Nav";

export interface ILayoutProps extends IChildrenElementProps {
  navProps?: INavProps;
}

export const Layout = ({ children, navProps }: ILayoutProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Nav {...navProps} />
      <main style={{ flex: "1 0 auto" }}>{children}</main>
      <Footer />
    </div>
  );
};
