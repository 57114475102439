export const capitalize = (string: string): string => {
  if (string.length <= 1) return string.toUpperCase();

  return string.substring(0, 1).toUpperCase() + string.substring(1);
};

const endsWith = (key, str) => str.substring(str.length - key.length) === key;

export const addSlashEnd = (fileName) =>
  endsWith("/", fileName) ? fileName : fileName + "/";

export const trail = addSlashEnd;
