import { useStaticQuery, graphql } from "gatsby";
import {
  Nav as FactorNav,
  NavSub,
  NavMain,
  NavMainLink,
  NavMainLinkDropdown,
  NavMainLinkDropdownCategory,
  NavMainLinkDropdownLink,
  Button,
  NavContext,
  Icon,
  Card,
} from "@lleed-partners/factor";
import { useContext, useMemo } from "react";
import { Link, useI18next, I18nextContext } from "gatsby-plugin-react-i18next";
import { ArrowRight32 } from "@carbon/icons-react";
import { trail } from "..";

export interface INavProps {
  /** Show or hide the subnav */
  showSubNav?: boolean;
}

export const Nav = ({ showSubNav = true }: INavProps) => {
  const { t } = useI18next();

  const { language } = useContext(I18nextContext);

  const {
    services: { edges: services },
    industries: { edges: industries },
    caseStudiesAndInsights: { edges: caseStudiesAndInsights },
  } = useStaticQuery(graphql`
    query {
      services: allMdx(
        filter: {
          frontmatter: { category: { eq: "service" }, service: { eq: null } }
        }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
            }
            fields {
              langKey
            }
            subServices {
              frontmatter {
                slug
                title
              }
            }
          }
        }
      }

      industries: allMdx(
        filter: { frontmatter: { category: { eq: "industry" } } }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
            }
            fields {
              langKey
            }
          }
        }
      }

      caseStudiesAndInsights: allMdx(
        filter: {
          frontmatter: {
            category: { in: ["case-study", "insight"] }
            published: { ne: false }
          }
        }
        sort: {
          fields: [frontmatter___modified, frontmatter___title]
          order: [DESC, ASC]
        }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
            }
            fields {
              langKey
            }
          }
        }
      }
    }
  `);

  const filteredServices = useMemo(() => {
    return services
      .filter(({ node }) => node.fields.langKey === language.split("-")[0])
      .map(({ node }) => ({
        ...node,
        subServices: node.subServices.sort((a, b) =>
          a.frontmatter.slug.localeCompare(b.frontmatter.slug)
        ),
      }));
  }, [language, services]);

  const filteredIndustries = useMemo(() => {
    return industries
      .filter(({ node }) => node.fields.langKey === language.split("-")[0])
      .map(({ node }) => ({
        ...node,
      }));
  }, [language, industries]);

  const filteredCaseStudiesAndInsights = useMemo(() => {
    return caseStudiesAndInsights
      .filter(({ node }) => node.fields.langKey === language.split("-")[0])
      .slice(0, 2);
  }, [language, caseStudiesAndInsights]);

  return (
    <FactorNav>
      <NavMain
        navLogoProps={{
          as: Link,
          to: "/",
          interactive: true,
          title: t("Home"),
        }}
        rightElements={
          <Button fullWidth={true} size="large" as={Link} to="/contact/">
            {t("Contact")}
          </Button>
        }
      >
        <NavMainLink
          as="span"
          id="services"
          trackId="nav-services"
          dropdown={
            <NavMainLinkDropdown intent="secondary">
              {filteredServices.map((service) => {
                return (
                  <NavMainLinkDropdownCategory
                    key={service.frontmatter.slug}
                    as={Link}
                    to={trail(service.frontmatter.slug)}
                    title={service.frontmatter.title}
                  >
                    {service.subServices.map((subService) => {
                      return (
                        <NavMainLinkDropdownLink
                          as={Link}
                          trackId={`nav-services-${subService.frontmatter.slug}`}
                          key={subService.frontmatter.slug}
                          to={trail(subService.frontmatter.slug)}
                        >
                          {subService.frontmatter.title}
                        </NavMainLinkDropdownLink>
                      );
                    })}
                  </NavMainLinkDropdownCategory>
                );
              })}
              <NavMainLinkDropdownCategory>
                <Card
                  interactive
                  as={Link}
                  to="/audit/"
                  title={t("Free digital audit")}
                  intent="primary"
                >
                  {t(
                    "Want to know more about your digital performance? Lleed & Partners offers a free digital audit program to help you know more."
                  )}
                </Card>
              </NavMainLinkDropdownCategory>
            </NavMainLinkDropdown>
          }
        >
          {t("Services")}
        </NavMainLink>
        <NavMainLink
          as="span"
          id="industries"
          trackId="nav-industries"
          dropdown={
            <NavMainLinkDropdown intent="secondary">
              <NavMainLinkDropdownCategory>
                {filteredIndustries.map((industry) => {
                  return (
                    <NavMainLinkDropdownLink
                      as={Link}
                      to={trail(industry.frontmatter.slug)}
                      key={industry.frontmatter.slug}
                      trackId={`nav-services-${industry.frontmatter.slug}`}
                    >
                      {industry.frontmatter.title}
                    </NavMainLinkDropdownLink>
                  );
                })}
              </NavMainLinkDropdownCategory>
            </NavMainLinkDropdown>
          }
        >
          {t("Industries")}
        </NavMainLink>
        <NavMainLink as={Link} to="/insights/" trackId="nav-insights">
          {t("Insights")}
        </NavMainLink>
        <NavMainLink
          as="a"
          rel="noopener"
          target="_blank"
          href="https://www.linkedin.com/company/lleedpartners/jobs/"
          trackId="nav-careers"
        >
          {t("Careers")}
        </NavMainLink>
      </NavMain>
      <div style={{ display: showSubNav ? "block" : "none" }}>
        <NavSub>
          <NavContext.Consumer>
            {({ intent }) => (
              <>
                <Button
                  style={{ whiteSpace: "nowrap" }}
                  intent={intent}
                  as={Link}
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                  trackId={`nav-sublink-metals-sales`}
                  to="/brochures/metals-sales/"
                >
                  {t("Automating metals sales processes")}
                </Button>
                {filteredCaseStudiesAndInsights.map(
                  ({ node: caseStudyOrInsight }) => {
                    return (
                      <Button
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        key={caseStudyOrInsight.frontmatter.slug}
                        intent={intent}
                        as={Link}
                        to={trail(caseStudyOrInsight.frontmatter.slug)}
                        rightIcon={<Icon icon={<ArrowRight32 />} />}
                        trackId={`nav-sublink-${caseStudyOrInsight.frontmatter.slug}`}
                      >
                        {caseStudyOrInsight.frontmatter.title}
                      </Button>
                    );
                  }
                )}
              </>
            )}
          </NavContext.Consumer>
        </NavSub>
      </div>
    </FactorNav>
  );
};
